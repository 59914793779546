
import {defineComponent, onMounted, ref} from "vue";
import {PaymentAggregation} from "@/data-models/payment-aggregation";
import {backendApi} from "@/utils/backend-api";
import {subPaths} from "@/utils/consts";
import {useRouter} from "vue-router";
import {loadingOverlayStateStore} from "@/stores/loading-overlay-state-store";
import {FontSizes} from "@/utils/font-sizes";
import Breadcrumbs from "@/components/Breadcrumbs.vue";

export default defineComponent({
    name: "PaymentAggregation",
    components: {Breadcrumbs},
    setup() {

        const aggregations = ref<PaymentAggregation[]>([] as PaymentAggregation[])

        const router = useRouter()

        onMounted(async () => {
            const resp = await backendApi.inquirePaymentAggregations()
            // console.log(resp)
            if (resp.isAuthorized) {
                aggregations.value = PaymentAggregation.fromJsonArrToArr(resp.value)
                // console.log(aggregations.value)
            } else {
                await router.push(subPaths.LOGIN)
            }

            loadingOverlayStateStore.commit("fadeout")
        })

        return {
            aggregations,
            FontSizes,
            ancestries: [
                {
                    name: "ホーム",
                    pathObj: subPaths.HOME
                }
            ],
        }
    }
})
