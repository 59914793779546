
import {defineComponent, onMounted, PropType, ref, watch} from "vue";
import {backendApi} from "@/utils/backend-api";
import {useRoute, useRouter} from "vue-router";
import {invoiceStatuses, logoutMessages, subPaths} from "@/utils/consts";
import {PaymentAmount} from "@/data-models/payment-amount";
import {PaymentHistory} from "@/data-models/payment-history";
import ApplyPrepaymentDialog from "@/components/SubmitPrepaymentDialog.vue";
import {amountToString} from "@/utils/string-utils";
import {Staff} from "@/data-models/staff";
import {authStore} from "@/stores/auth-store";
import {authLevels} from "@/data-models/auth";
import {DailyRecord, paymentTypes} from "@/data-models/daily-record";
import {addMonths, format, isSameMonth} from "date-fns";
import {loadingOverlayStateStore} from "@/stores/loading-overlay-state-store";
import {FontSizes} from "@/utils/font-sizes";
import Breadcrumbs, {ancestryType} from "@/components/Breadcrumbs.vue";
import ja from "date-fns/locale/ja"
import StaffNotificationDialog from "@/components/StaffNotificationDialog.vue";

export default defineComponent({
    name: "MyPage",
    components: {Breadcrumbs, StaffNotificationDialog},
    setup() {

        const staffId = ref<number>(-1),
            staff = ref<Staff>(new Staff()),
            ancestries = ref<ancestryType[]>([] as ancestryType[]),
            acceptApproval = ref<boolean>(false)


        const route = useRoute(),
            router = useRouter()

        const update = async () => {

            const staffResp = await backendApi.loadStaffById(staffId.value),
                statusResp = await backendApi.inquireSiteStatus()

            if (staffResp.isAuthorized && statusResp.isAuthorized) {
                staff.value = Staff.fromJson(staffResp.value)

                ancestries.value = [
                    {
                        name: "ホーム",
                        pathObj: subPaths.HOME
                    },
                    {
                        name: staff.value.branch,
                        pathObj: {
                            path: subPaths.BRANCH_PAGE,
                            query: {
                                name: staff.value.branch
                            }
                        }
                    }
                ]

                acceptApproval.value = statusResp.value === invoiceStatuses.APPROVAL_ACCEPT

            } else {
                await router.push({
                    path: subPaths.LOGGED_OUT,
                    query: {
                        logout_message: logoutMessages.TOKEN_EXPIRED.key
                    }
                })
            }
        }

        const onClickMoveToPrepayment = () => {
            loadingOverlayStateStore.commit("switchLoadingState", {
                show: true,
                message: "前払い申し込みページへ移動中…"
            })

            router.push({
                path: subPaths.MY_PAGE_PREPAYMENT,
                query: {
                    staff_id: staff.value.staffId,
                },
            })
        }

        const onClickMoveToInvoiceOfMonth = () => {

            if (!acceptApproval.value) {
                return
            }

            loadingOverlayStateStore.commit("switchLoadingState", {
                show: true,
                message: "月払い請求書発行ページへ移動中…",
                shouldTimeOut: false

            })

            router.push({
                path: subPaths.MY_PAGE_INVOICE_OF_MONTH,
                query: {
                    staff_id: staff.value.staffId,
                },
            })
        }

        const onClickPostingDataDiff = () => {

            loadingOverlayStateStore.commit("switchLoadingState", {
                show: true,
                message: "配布記録一覧ページ読み込み中…",
                shouldTimeOut: false,
            })

            router.push({
                path: subPaths.MY_PAGE_POSTING_DATA_DIFF,
                query: {
                    staff_id: staff.value.staffId,
                },
            })
        }

        const onClickPaymentStop = () => {

            loadingOverlayStateStore.commit("switchLoadingState", {
                show: true,
                message: "支払い停止・再開ページ読み込み中…",
                shouldTimeOut: false,
            })

            router.push({
                path: subPaths.MY_PAGE_PAYMENT_STOP,
                query: {
                    staff_id: staff.value.staffId,
                },
            })
        }

        const onClickInvoiceResend = () => {

            loadingOverlayStateStore.commit("switchLoadingState", {
                show: true,
                message: "請求書再送ページ読み込み中…",
                shouldTimeOut: false,
            })

            router.push({
                path: subPaths.MY_PAGE_INVOICE_RESEND,
                query: {
                    staff_id: staff.value.staffId,
                },
            })
        }

        onMounted(async () => {

            const stId = route.query.staff_id as string
            if (stId) {
                staffId.value = parseInt(stId)
                await update()
            }

            loadingOverlayStateStore.commit("fadeout")
        })

        return {
            staffId,
            staff,
            FontSizes,
            ancestries,
            authStore,
            authLevels,
            onClickMoveToPrepayment,
            onClickMoveToInvoiceOfMonth,
            acceptApproval,
            onClickPostingDataDiff,
            onClickPaymentStop,
            onClickInvoiceResend,
        }
    }
})
