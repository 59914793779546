import {createStore} from "vuex";
import {v4 as uuidv4} from "uuid";

export const loadingOverlayStateStore = createStore({
    state: {
        show: false,
        message: "Loading...",
        shownAt: 0
    },
    mutations: {
        switchLoadingState: (state, payload) => {

            let shouldTimeOut = true
            if (payload.shouldTimeOut !== undefined
                && !payload.shouldTimeOut) {
                shouldTimeOut = false
            }

            if (payload.show) {

                state.shownAt = Date.now()

                if (payload.message) {
                    console.log("payload.message: " + payload.message)
                    state.message = payload.message
                } else {
                    state.message = "ロード中…"
                }

                if (shouldTimeOut) {
                    // 10秒で消えるようにする。
                    setTimeout(() => {
                        // 表示から10秒後に表示開始後9秒以上たっていたら消す。
                        if (state.show && Date.now() - state.shownAt > 9000) {
                            state.show = false
                            state.shownAt = 0
                            console.log("Loading overlay hidden due to timed out!!")
                        }
                    }, 10000)
                }
            }
            state.show = payload.show
        },
        fadeout: (state) => {
            state.show = false
            state.shownAt = 0
        }
    }
})