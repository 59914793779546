import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-59904bfc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "cell-frame row" }
const _hoisted_2 = { class: "col-1 align-self-center" }
const _hoisted_3 = { class: "wrap-text" }
const _hoisted_4 = { class: "col-2 align-self-center" }
const _hoisted_5 = { class: "wrap-text" }
const _hoisted_6 = {
  key: 0,
  class: "col-1 align-self-center"
}
const _hoisted_7 = { class: "wrap-text" }
const _hoisted_8 = { class: "col-8 text-left px-1" }
const _hoisted_9 = { class: "col-4 align-self-center px-1" }
const _hoisted_10 = { class: "wrap-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.invoiceApproval.staffId), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("a", {
        href: "#",
        onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onClickStaffLink && _ctx.onClickStaffLink(...args)), ["prevent"]))
      }, [
        _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.invoiceApproval.staff.userName), 1)
      ])
    ]),
    (!_ctx.isForBranchPage)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.invoiceApproval.staff.branch), 1)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(["col-5 align-self-center container", {'col-6': _ctx.isForBranchPage}])
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.invoiceApproval.messages, (msg) => {
        return (_openBlock(), _createElementBlock("div", {
          key: msg.vForKey,
          class: "row",
          style: _normalizeStyle({fontSize: _ctx.FontSizes.small1})
        }, [
          _createElementVNode("div", _hoisted_8, "● " + _toDisplayString(msg.message), 1),
          _createElementVNode("div", _hoisted_9, _toDisplayString(msg.datetime), 1)
        ], 4))
      }), 128))
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(["col-3 align-self-center mx-n1", _ctx.stateStyleClass()])
    }, [
      _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.approvalStateText()), 1)
    ], 2)
  ]))
}