import {PaymentAmount} from "@/data-models/payment-amount";
import {parse} from "date-fns";
import {datetimeFormat} from "@/utils/consts";

export class PostingRecordDiff extends PaymentAmount {

    diffState: ""|"added"|"deleted"|"changed" = ""
    oldData: null | PaymentAmount = null

    static fromJson(obj: any): PostingRecordDiff {

        const rDiff = new PostingRecordDiff()

        rDiff.id = obj.id
        rDiff.postingDate = parse(obj.posting_date, datetimeFormat, new Date())
        rDiff.staffId = obj.staff_id
        rDiff.productId = obj.product_id
        rDiff.productName = obj.product_name
        rDiff.areaAddress = obj.area_address
        rDiff.place = obj.place
        rDiff.plannedCount = obj.planned_count
        rDiff.changedCount = obj.changed_count
        rDiff.adjustPrice = obj.adjust_price
        rDiff.areaId = obj.area_id
        rDiff.voucherId = obj.voucher_id
        rDiff.bikeId = obj.bike_id
        rDiff.mobileId = obj.mobile_id
        rDiff.sPrice = obj.s_price
        rDiff.resultCount = obj.result_count
        rDiff.amount = obj.amount


        if (obj.diffState) {
            rDiff.diffState = obj.diffState
        }

        return rDiff
    }

    static fromJsonArrToArr (objArr: Array<any>): PostingRecordDiff[] {
        const arr = [] as PostingRecordDiff[]
        objArr.forEach(obj => {
            arr.push(this.fromJson(obj))
        })
        return arr
    }

    get stringForSearch(): string {
        let str =  super.stringForSearch

        if (this.diffState.length > 0) {
            str += this.diffStateText
        }

        return str
    }

    get diffStateText (): string {
        switch (this.diffState) {
            case "added":
                return "追加"
            case "changed":
                return "変更"
            case "deleted":
                return "削除"
            default:
                return ""
        }
    }



}