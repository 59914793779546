
import {defineComponent, PropType, ref, watch} from "vue";
import DialogFrame from "@/components/DialogFrame.vue";
import {amountToString} from "@/utils/string-utils";
import {backendApi} from "@/utils/backend-api";
import {loadingOverlayStateStore} from "@/stores/loading-overlay-state-store";
import {FontSizes} from "@/utils/font-sizes";

const submitDialogCondition = {
    INPUTTING: "apply_dialog_condition__inputting",
    INQUIRING: "apply_dialog_condition__inquiring",
    SHOW_RESULT: "apply_dialog_condition__show_result"
}

export default defineComponent({
    name: "SubmitPrepaymentDialog",
    components: {
        DialogFrame,
    },
    props: {
        show: {
            type: Boolean,
            required: true,
        },
        close: {
            type: Function as PropType<() => void>,
            required: true
        },
        staffId: {
            type: Number,
            required: true,
        },
        prepaymentBalance: {
            type: Number,
            required: true,
        },
        onFinishSubmitPrepayment: {
            type: Function as PropType<(success: boolean) => void>,
            required: true,
        }
    },
    setup(props) {

        const dialogCondition = ref<string>(""),
            amountToSubmit = ref<number>(0),
            resultText = ref<string>(""),
            second = ref<number>(0),
            dialogWidth = ref<number>(400),
            prepaymentUnit = 1000

        watch(() => props.show, () => {
            if (props.show) {

                const windowWidth = window.innerWidth
                if (windowWidth < 400) {
                    dialogWidth.value = windowWidth - 20
                }

                dialogCondition.value = submitDialogCondition.INPUTTING
            }
        })

        const canSubmit = (): boolean => {
            return amountToSubmit.value * prepaymentUnit <= props.prepaymentBalance
                    && amountToSubmit.value > 0
        }

        let isSubmitSuccessful = false

        const onClickSubmit = async () => {
            dialogCondition.value = submitDialogCondition.INQUIRING

            const resp = await backendApi.makePrepaymentRequest(props.staffId, amountToSubmit.value * prepaymentUnit)
            isSubmitSuccessful = resp.isAuthorized
            if (isSubmitSuccessful) {
                dialogCondition.value = submitDialogCondition.SHOW_RESULT
                resultText.value = `前払い申請が完了しました： ${amountToSubmit.value * prepaymentUnit}円`
                startCountDown()
            } else {
                //
            }
        }

        const clickCloseOnResult = () => {
            props.onFinishSubmitPrepayment(isSubmitSuccessful)
            props.close()
        }

        const startCountDown = () => {
            second.value = 3
            const id = window.setInterval(() => {
                second.value--
                if (second.value <= 0) {
                    window.clearInterval(id)
                    props.onFinishSubmitPrepayment(isSubmitSuccessful)
                    props.close()
                }
            }, 1000)
        }

        // const amountZerosLeft = (): string => {
        //
        //     let amount = amountToSubmit.value
        //     const digitWidth = FontSizes.normalInPx - 4,
        //         indent = 10
        //
        //     if (amount <= 1) {
        //         return (digitWidth + indent) + "px"
        //     } else {
        //         let amountDigits = 0
        //
        //         while (amount >= 1) {
        //             amount /= 10
        //             amountDigits++
        //         }
        //         const amountWidth = digitWidth * amountDigits + 10
        //         return amountWidth + "px"
        //     }
        // }


        return {
            amountToString,
            submitDialogCondition,
            dialogCondition,
            amountToSubmit,
            canSubmit,
            onClickSubmit,
            clickCloseOnResult,
            resultText,
            // second,
            dialogWidth,
            // amountZerosLeft,
        }
    }
})
