
import {computed, defineComponent, PropType, ref} from "vue";

export default defineComponent({
    name: "MenuButton",
    props: {
        keyAsId: {
            type: String,
            required: true
        },
        data: {
            type: Object,
            required: true
        },
        onClick: {
            type: Function as PropType<(data: any, btnDiv: HTMLDivElement) => void>,
            required: true
        }
    },
    setup(props) {

        const btnDivId = computed<string>(() => {
            return `btn_div_${props.keyAsId}`
        })

        const onClickButton = () => {

            const btnDiv = document.getElementById(btnDivId.value) as HTMLDivElement

            if (btnDiv) {
                props.onClick(props.data, btnDiv)
            }
        }

        return {
            onClickButton,
            btnDivId,
        }

    }
})
