import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-08f077b3"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "row justify-content-center" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "row mt-4" }
const _hoisted_7 = { class: "row" }
const _hoisted_8 = { class: "row" }
const _hoisted_9 = { class: "row" }
const _hoisted_10 = { class: "row my-3" }
const _hoisted_11 = { class: "col col-lg-8 status-control-box container" }
const _hoisted_12 = { class: "row my-2" }
const _hoisted_13 = { class: "row justify-content-center toggle-row" }
const _hoisted_14 = { class: "col-3" }
const _hoisted_15 = { class: "col-6" }
const _hoisted_16 = { class: "wrap-text" }
const _hoisted_17 = { class: "row justify-content-center toggle-row" }
const _hoisted_18 = { class: "col-3" }
const _hoisted_19 = { class: "col-6" }
const _hoisted_20 = { class: "wrap-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DraftInvoiceDownloader = _resolveComponent("DraftInvoiceDownloader")!
  const _component_ToggleSwitch = _resolveComponent("ToggleSwitch")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: "col h2",
        style: _normalizeStyle({fontSize: _ctx.FontSizes.large2})
      }, "管理者用ページ", 4)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", {
        class: "col h3",
        style: _normalizeStyle({fontSize: _ctx.FontSizes.large1})
      }, "支店を選択", 4)
    ]),
    _createElementVNode("div", _hoisted_4, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.branches, (branch) => {
        return (_openBlock(), _createElementBlock("div", {
          key: branch.vForKey(),
          class: "card col-md-3 col-sm-5",
          onClick: ($event: any) => (_ctx.onClickBranch(branch))
        }, _toDisplayString(branch.name), 9, _hoisted_5))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", {
        class: "col-md-3 col-sm mx-1 card",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClickPaymentAggregation && _ctx.onClickPaymentAggregation(...args)))
      }, "日ごとの集計へ"),
      _createElementVNode("div", {
        class: "col-md-3 col-sm mx-1 card",
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onClickInvoiceMessages && _ctx.onClickInvoiceMessages(...args)))
      }, "請求承認メッセージ一覧"),
      _createElementVNode("div", {
        class: "col-md-3 col-sm mx-1 card",
        onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onClickPostingRecordsDiff && _ctx.onClickPostingRecordsDiff(...args)))
      }, "配布記録データ差分表示")
    ]),
    _createElementVNode("div", _hoisted_7, [
      _withDirectives(_createElementVNode("div", {
        class: "col-md-3 col-sm mx-1 card",
        onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onClickDownloadPrepaymentFile && _ctx.onClickDownloadPrepaymentFile(...args)))
      }, "前払い用CSVファイル", 512), [
        [_vShow, _ctx.hasAccountingAuth()]
      ]),
      _withDirectives(_createElementVNode("div", {
        class: "col-md-3 col-sm mx-1 card",
        onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.onClickDownloadMonthlyPaymentTXT && _ctx.onClickDownloadMonthlyPaymentTXT(...args)))
      }, "月次支払い用全銀ファイル", 512), [
        [_vShow, _ctx.hasBoardMemberAuth()]
      ]),
      (_ctx.hasBoardMemberAuth())
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "col-md-3 col-sm mx-1 card",
            onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.onClickDownloadMonthlyPaymentExcel && _ctx.onClickDownloadMonthlyPaymentExcel(...args)))
          }, "月次支払い用Excelファイル"))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        class: "col-md-3 col-sm mx-1 card",
        onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.onClickNegativeAmountListExcel && _ctx.onClickNegativeAmountListExcel(...args)))
      }, "前払い額超過分Excelファイル")
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createVNode(_component_DraftInvoiceDownloader, { class: "col" })
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("div", {
        class: "col-3 mx-1 my-2 card",
        onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.onClickMoveToHaifuApp && _ctx.onClickMoveToHaifuApp(...args)))
      }, "配布報告管理システムへ移動")
    ]),
    _createElementVNode("div", _hoisted_10, [
      _createElementVNode("div", _hoisted_11, [
        _createElementVNode("div", _hoisted_12, [
          _createElementVNode("div", {
            class: "col h4 text-left",
            style: _normalizeStyle({fontSize: _ctx.FontSizes.large1})
          }, "請求承認受付の管理", 4)
        ]),
        _createElementVNode("div", _hoisted_13, [
          _createElementVNode("div", _hoisted_14, [
            _createVNode(_component_ToggleSwitch, {
              class: "toggle",
              toggled: _ctx.acceptApproval,
              "onUpdate:toggled": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.acceptApproval) = $event)),
              "before-change": _ctx.beforeAcceptApprovalStateChange
            }, null, 8, ["toggled", "before-change"])
          ]),
          _createElementVNode("div", _hoisted_15, [
            _createElementVNode("span", _hoisted_16, _toDisplayString(_ctx.approvalAcceptText), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_17, [
          _createElementVNode("div", _hoisted_18, [
            _createVNode(_component_ToggleSwitch, {
              class: "toggle",
              toggled: _ctx.invoiceFixed,
              "onUpdate:toggled": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.invoiceFixed) = $event)),
              "before-change": _ctx.beforeInvoiceFixedStateChange
            }, null, 8, ["toggled", "before-change"])
          ]),
          _createElementVNode("div", _hoisted_19, [
            _createElementVNode("span", _hoisted_20, _toDisplayString(_ctx.invoiceFixedText), 1)
          ])
        ])
      ])
    ])
  ]))
}