
import {defineComponent, onMounted, PropType, ref, watch} from "vue";
import {backendApi} from "@/utils/backend-api";
import {useRoute, useRouter} from "vue-router";
import {invoiceStatuses, logoutMessages, subPaths} from "@/utils/consts";
import {PaymentAmount} from "@/data-models/payment-amount";
import {PaymentHistory} from "@/data-models/payment-history";
import ApplyPrepaymentDialog from "@/components/SubmitPrepaymentDialog.vue";
import {amountToString} from "@/utils/string-utils";
import {Staff} from "@/data-models/staff";
import {authStore} from "@/stores/auth-store";
import {authLevels} from "@/data-models/auth";
import {DailyRecord, paymentTypes} from "@/data-models/daily-record";
import {addMonths, format, isSameMonth} from "date-fns";
import {loadingOverlayStateStore} from "@/stores/loading-overlay-state-store";
import {FontSizes} from "@/utils/font-sizes";
import Breadcrumbs, {ancestryType} from "@/components/Breadcrumbs.vue";
import ja from "date-fns/locale/ja"
import {InvoiceStatus} from "@/data-models/invoice-status";
import {toYearMonthStr} from "../utils/date-utils";
import {toastStore} from "@/stores/toast-store";


export default defineComponent({
    name: "InvoiceResendPage",
    methods: {toYearMonthStr},
    components: {Breadcrumbs},
    setup() {

        const staffId = ref<number>(-1),
            staff = ref<Staff>(new Staff()),
            ancestries = ref<ancestryType[]>([] as ancestryType[]),
            invoiceStatuses = ref<InvoiceStatus[]>([] as InvoiceStatus[])


        const route = useRoute(),
            router = useRouter()

        const update = async () => {

            const staffResp = await backendApi.loadStaffById(staffId.value),
                    statusResp = await backendApi.inquireSiteStatus()

            if (staffResp.isAuthorized && statusResp.isAuthorized) {
                staff.value = Staff.fromJson(staffResp.value)

                ancestries.value = [
                    {
                        name: "ホーム",
                        pathObj: subPaths.HOME
                    },
                    {
                        name: staff.value.branch,
                        pathObj: {
                            path: subPaths.BRANCH_PAGE,
                            query: {
                                name: staff.value.branch
                            }
                        }
                    },
                    {
                        name: `${staff.value.displayName}さんのページ`,
                        pathObj: {
                            path: subPaths.MY_PAGE,
                            query: {
                                staff_id: staff.value.staffId,
                            }
                        }
                    }
                ]

            } else {
                await router.push({
                    path: subPaths.LOGGED_OUT,
                    query: {
                        logout_message: logoutMessages.TOKEN_EXPIRED.key
                    }
                })
            }
        }

        const updateInvoiceStatus = async () => {

            invoiceStatuses.value.splice(0)
            const resp = await backendApi.inquireInvoiceStatus(staffId.value)
            if (resp.isSuccessful) {
                console.log(resp.value)
                const statuses = InvoiceStatus.fromJsonArrToArr(resp.value)
                statuses.forEach(st => {
                    invoiceStatuses.value.push(st)
                })
            } else {
                await router.push({
                    path: subPaths.LOGGED_OUT,
                    query: {
                        logout_message: logoutMessages.TOKEN_EXPIRED.key
                    }
                })
            }
        }
        
        const onClickResendInvoice = async (invoiceStatus: InvoiceStatus) => {

            loadingOverlayStateStore.commit("switchLoadingState", {
                show: true,
                message: "請求書再送中…",
                shouldTimeOut: true,
            })

            const ymStr = format(invoiceStatus.yearMonth, "yyyy/MM")
            const resp = await backendApi.requestResendInvoice(staffId.value, ymStr)
            if (resp.isSuccessful) {
                toastStore.commit("post", {message: `${ymStr}の請求書を再送しました`})
            } else {
                await router.push({
                    path: subPaths.LOGGED_OUT,
                    query: {
                        logout_message: logoutMessages.TOKEN_EXPIRED.key
                    }
                })
            }

            loadingOverlayStateStore.commit("fadeout")
        }

        const transferFeeStr = (st: InvoiceStatus): string => {
            return st.invoice.amount > 0 ? Math.floor(st.invoice.transferFee).toLocaleString() : "--"
        }

        onMounted(async () => {

            loadingOverlayStateStore.commit("switchLoadingState", {
                show: true,
                message: "請求書再送ページ読み込み中…",
                shouldTimeOut: false,
            })

            const stId = route.query.staff_id as string
            if (stId) {
                staffId.value = parseInt(stId)
                await update()
                await updateInvoiceStatus()
            }

            loadingOverlayStateStore.commit("fadeout")
        })

        return {
            staffId,
            staff,
            FontSizes,
            ancestries,
            authStore,
            authLevels,
            invoiceStatuses,
            onClickResendInvoice,
            transferFeeStr,
        }
    }
})
