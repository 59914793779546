import {ApiResponse} from "@/data-models/api-response";

export const downloadZIPFile = async (resp: ApiResponse): Promise<void> => {
    await downloadFileCore(resp, "data:application/zip;charset=UTF-8;base64,", "application/zip")
}

export const downloadExcelFile = async (resp: ApiResponse): Promise<void> => {
    await downloadFileCore(resp, "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8;base64,", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")
}

export const downloadCSV = async (resp: ApiResponse): Promise<void> => {
    await downloadFileCore(resp, "data:text/csv;charset=UTF-8;base64,", "text/csv")
}

export const downloadShiftJISTXT = async (resp: ApiResponse): Promise<void> => {
    await downloadFileCore(resp, "data:text/plain;charset=SHIFT_JIS;base64,", "text/plain")
}

export const downloadUTF8TXT = async (resp: ApiResponse): Promise<void> => {
    await downloadFileCore(resp, "data:text/plain;charset=UTF-8;base64,", "text/plain")
}

const downloadFileCore = async (resp: ApiResponse, prefix: string, mimeType: string): Promise<void> => {
    const fileData = resp.value.file_data;
    const fileName = resp.value.file_name;

    const a = document.createElement("a");
    a.href = prefix + fileData

    a.download = fileName;
    a.type = mimeType
    a.click();
}