
import {defineComponent, onMounted, ref} from "vue";

export default defineComponent({
    name: "PDFViewer",
    props: {
        pdfSrcProp: {
            type: String,
            required: true
        }
    },
})
