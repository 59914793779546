import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "row mb-3" }
const _hoisted_3 = { class: "col" }
const _hoisted_4 = { class: "row justify-content-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DialogFrame = _resolveComponent("DialogFrame")!

  return (_openBlock(), _createBlock(_component_DialogFrame, {
    "show-dialog": _ctx.confirmDialogStateStore.state.show,
    "on-click-close": _ctx.onCanceled,
    title: _ctx.confirmDialogStateStore.state.title,
    width: 600,
    height: 150
  }, {
    dialogContent: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.confirmDialogStateStore.state.message), 1)
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("button", {
            class: "col-4 mx-2 btn btn-secondary",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onCanceled && _ctx.onCanceled(...args)))
          }, "キャンセル"),
          _createElementVNode("button", {
            class: "col-4 btn btn-info",
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onAffirmed && _ctx.onAffirmed(...args)))
          }, _toDisplayString(_ctx.confirmDialogStateStore.state.affirmativeText), 1)
        ])
      ])
    ]),
    _: 1
  }, 8, ["show-dialog", "on-click-close", "title"]))
}