
import {defineComponent, onMounted, ref} from "vue";
import {backendApi} from "@/utils/backend-api";
import {datetimeFormat, subPaths} from "@/utils/consts";
import {useRouter} from "vue-router";
import {loadingOverlayStateStore} from "@/stores/loading-overlay-state-store";
import {FontSizes} from "@/utils/font-sizes";
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import {PostingRecordAllDiffSummary} from "@/data-models/posting-record-all-diff-summary";
import {format} from "date-fns";

export default defineComponent({
    name: "PostingRecordsAllDiff",
    components: {Breadcrumbs},
    setup() {

        const recordDiffSummary = ref<PostingRecordAllDiffSummary>(new PostingRecordAllDiffSummary())

        const router = useRouter()

        onMounted(async () => {
            const diffResp = await backendApi.inquirePostingRecordsAllDiff()
            console.log(diffResp)

            if (diffResp.isAuthorized) {

                recordDiffSummary.value = PostingRecordAllDiffSummary.fromJson(diffResp.value)
                console.log(recordDiffSummary.value)

            } else {
                await router.push(subPaths.LOGIN)
            }

            loadingOverlayStateStore.commit("fadeout")
        })

        const updatedDTString = (): string => {
            return format(recordDiffSummary.value.updatedAt as Date, datetimeFormat)
        }

        const onClickBranchSummary = (branchName: string) => {

            loadingOverlayStateStore.commit("switchLoadingState", {
                show: true,
                message: `${branchName}の差分概要に移動中…`
            })

            router.push({
                path: subPaths.POSTING_RECORDS_BRANCH_DIFF,
                query: {
                    name: branchName
                }
            })
        }

        return {
            FontSizes,
            ancestries: [
                {
                    name: "ホーム",
                    pathObj: subPaths.HOME
                }
            ],
            recordDiffSummary,
            updatedDTString,
            onClickBranchSummary,
        }
    }
})
