/**
 * PaymentAmountとPaymentHistoryの配列を受け取り、日ごとに集計した配列にして返す。
 */
import {PaymentAmount} from "@/data-models/payment-amount";
import {PaymentHistory} from "@/data-models/payment-history";
import {format, isSameDay, isSameMonth, toDate} from "date-fns";
import {dateFormat} from "@/utils/consts";

export interface DateItem {
    date: Date
}

export const paymentTypes = {
    NORMAL_PAYMENT: "normal",
    ADVANCE_PAYMENT: "advance",
    COMPENSATION: "compensation"
}

const to2DimArr = (dateItems: DateItem[]): DateItem[][] => {
    const arr2Dim = [] as DateItem[][]

    for (let i = 0; i < dateItems.length; i++) {
        const item = dateItems[i]

        let existsArrOfDate = false
        for (let j = 0; j < arr2Dim.length; j++) {
            if (isSameDay(arr2Dim[j][0].date, item.date)) {
                arr2Dim[j].push(item)
                existsArrOfDate = true
                break
            }
        }

        if (!existsArrOfDate) {
            // 日付の子配列が存在しなかった。
            const arr = [] as DateItem[]
            arr.push(item)
            arr2Dim.push(arr)
        }
    }
    return arr2Dim
}

export class DailyRecord {

    date = new Date()
    amount = 0
    isAvailableForPrepayment = false
    description = ""
    paymentType = ""

    get formattedDate (): string {
        return format(this.date, dateFormat)
    }

    get vForKey (): string {
        return `daily_record__${this.formattedDate}`
    }

    get amountString (): string {
        if (this.amount >= 0) {
            return Math.round(this.amount).toLocaleString()
        }
        return Math.round(this.amount * -1).toLocaleString()
    }

    static fromPaymentAmounts(pAmounts: PaymentAmount[]): DailyRecord[] {

        // 日付ごとに2次元配列に分ける
        const pAmounts2Dim = to2DimArr(pAmounts)
        const records = [] as DailyRecord[]
        for (let i = 0; i < pAmounts2Dim.length; i++) {
            const arr = pAmounts2Dim[i]

            const record = new DailyRecord()
            record.date = toDate(arr[0].date)
            record.isAvailableForPrepayment = isSameMonth(record.date, new Date())

            record.description = "報酬"
            // if (!record.isAvailableForPrepayment) {
            //     record.description += "（前払い不可）"
            // }

            record.paymentType = paymentTypes.COMPENSATION
            record.isAvailableForPrepayment = isSameMonth(record.date, new Date())

            for (let j = 0; j < arr.length; j++) {
                const pAmount = arr[j] as PaymentAmount
                record.amount += pAmount.amount
            }
            records.push(record)
        }
        return records
    }

    static fromPaymentHistories(pHistories: PaymentHistory[]): DailyRecord[] {
        // 日付ごとに2次元配列に分ける
        const pHistories2Dim = to2DimArr(pHistories)
        const records = [] as DailyRecord[]
        for (let i = 0; i < pHistories2Dim.length; i++) {
            const arr = pHistories2Dim[i]
            const record = new DailyRecord()
            record.date = toDate(arr[0].date)
            record.paymentType = (arr[0] as PaymentHistory).paymentType

            let systemFeeSum = 0,
                transferFeeSum = 0
            for (let j = 0; j < arr.length; j++) {
                const pHist = arr[j] as PaymentHistory
                record.amount += pHist.amount
                systemFeeSum += pHist.systemFee //+ pHist.systemFeeTax)
                transferFeeSum += pHist.transferFee //+ pHist.transferFeeTax)
            }

            if (record.paymentType === paymentTypes.ADVANCE_PAYMENT) {
                record.description = `前払い（内システム手数料:${systemFeeSum.toLocaleString()}円、振込手数料:${transferFeeSum.toLocaleString()}円）`
            } else {
                record.description = "通常払い"
            }
            records.push(record)
        }
        return records
    }

}