import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6b67fd14"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id"]
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Transition, { name: "fade" }, {
    default: _withCtx(() => [
      _withDirectives(_createElementVNode("div", {
        class: "popup-overlay",
        id: _ctx.overlayId,
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.hide && _ctx.hide(...args)))
      }, [
        _createElementVNode("div", {
          class: "popup-frame",
          style: _normalizeStyle({width: _ctx.frameWidth + 'px',
                            height: _ctx.frameHeight + 'px',
                            fontSize: _ctx.FontSizes.small1,
                            left: _ctx.posX + 'px',
                            top: _ctx.posY + 'px'})
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
            return (_openBlock(), _createElementBlock("div", {
              key: item.itemId,
              class: "menu-item",
              onClick: ($event: any) => (_ctx.onClickItem(item)),
              style: _normalizeStyle({height: _ctx.itemHeight + 'px',
                                lineHeight: _ctx.itemHeight + 'px'})
            }, _toDisplayString(item.text), 13, _hoisted_2))
          }), 128))
        ], 4)
      ], 8, _hoisted_1), [
        [_vShow, _ctx.show]
      ])
    ]),
    _: 1
  }))
}