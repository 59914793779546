

import DialogFrame from "./DialogFrame.vue";
import {confirmDialogStateStore} from "@/stores/confirm-dialog-state-store";
import {defineComponent} from "vue";

export default defineComponent({
    name: "ConfirmDialog",
    components: {DialogFrame},
    // watch: {
    //     show() {
    //         console.log(confirmDialogStateStore.state.title)
    //     }
    // },
    setup() {

        const onCanceled = () => {
            confirmDialogStateStore.commit("onConfirmed", {affirmed: false})
        }

        const onAffirmed = () => {
            confirmDialogStateStore.commit("onConfirmed", {affirmed: true})
        }

        // onMounted(() => {
        //     console.log(confirmDialogStateStore.state.title)
        // })

        return {
            confirmDialogStateStore,
            onCanceled,
            onAffirmed,
        }
    }
})
