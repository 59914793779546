
import {defineComponent, onMounted, ref, watch} from "vue";
import {useRoute, useRouter} from "vue-router";
import {backendApi} from "@/utils/backend-api";
import {Staff} from "@/data-models/staff";
import {logoutMessages, subPaths} from "@/utils/consts";
import {loadingOverlayStateStore} from "@/stores/loading-overlay-state-store";
import ClearableInput from "@/components/ClearableInput.vue";
import {FontSizes} from "@/utils/font-sizes";
import Breadcrumbs, {ancestryType} from "@/components/Breadcrumbs.vue";
import {InvoiceApproval} from "@/data-models/invoice-approval";
import InvoiceApprovalListCell from "@/components/InvoiceApprovalListCell.vue";
import {confirmDialogStateStore} from "@/stores/confirm-dialog-state-store";
import BranchApprovalAffectedListDialog from "@/components/BranchApprovalAffectedListDialog.vue";

export default defineComponent({
    name: "ApprovalListForBranch",
    components: {BranchApprovalAffectedListDialog, InvoiceApprovalListCell, Breadcrumbs, ClearableInput},
    setup() {

        const acceptState = {
            all: "all",
            accept : "accept",
            notAccept: "not_accept",
            approvedByBranch: "approved_by_branch",
        }

        const route = useRoute(),
            router = useRouter()

        const  searchText = ref<string>(""),
            branchName = ref<string>(""),
            backwardMonthCountText = ref<string>("0"),
            searchedApprovals = ref<InvoiceApproval[]>([] as InvoiceApproval[]),
            selectedAcceptState = ref<string>(acceptState.all),
            showAffectedListDialog = ref<boolean>(false)

        let approvals = [] as InvoiceApproval[]

        const updateApprovals = async () => {
            // console.log(backwardMonthCountText.value)
            const resp = await backendApi.inquireApprovalListForBranch(branchName.value, parseInt(backwardMonthCountText.value))
            if (resp.isAuthorized) {
                approvals = InvoiceApproval.fromJsonArrToArr(resp.value)
                console.log(approvals)
            } else {
                await router.push({
                    path: subPaths.LOGGED_OUT,
                    query: {
                        logout_message: logoutMessages.TOKEN_EXPIRED.key
                    }
                })
            }
        }

        const updateSearchedApprovals = () => {
            searchedApprovals.value.splice(0)
            filterApprovalsByAcceptState().forEach(apprv => {
                if (searchText.value.length === 0) {
                    searchedApprovals.value.push(apprv)
                } else {
                    if (apprv.stringForSearch.includes(searchText.value)) {
                        searchedApprovals.value.push(apprv)
                    }
                }
            })
        }

        watch(() => backwardMonthCountText.value, async () => {
            await updateApprovals()
            updateSearchedApprovals()
        })

        watch(() => searchText.value, () => {
            updateSearchedApprovals()
        })

        watch(() => selectedAcceptState.value, () => {
            updateSearchedApprovals()
        })

        const filterApprovalsByAcceptState = (): InvoiceApproval[] => {
            const approvals2 = [] as InvoiceApproval[]
            approvals.forEach(apprv => {
                if (selectedAcceptState.value === acceptState.accept) {
                    if (apprv.accept && apprv.state === "") {
                        approvals2.push(apprv)
                    }
                } else if (selectedAcceptState.value === acceptState.notAccept) {
                    if (!apprv.accept) {
                        approvals2.push(apprv)
                    }
                } else if (selectedAcceptState.value === acceptState.approvedByBranch) {
                    if (apprv.accept && apprv.state === acceptState.approvedByBranch) {
                        approvals2.push(apprv)
                    }
                } else {
                    approvals2.push(apprv)
                }
            })
            return approvals2
        }


        onMounted(async () => {

            const branchNameQuery = route.query.name as string
            if (branchNameQuery) {

                branchName.value = branchNameQuery
                console.log(branchName.value)

                searchText.value = ""

                await updateApprovals()
                updateSearchedApprovals()

            }


            loadingOverlayStateStore.commit("fadeout")
        })

        const ancestries = (): ancestryType[] => {
            return [
                {
                    name: "ホーム",
                    pathObj: subPaths.HOME
                },
                {
                    name: branchName.value,
                    pathObj: {
                        path: subPaths.BRANCH_PAGE,
                        query: {
                            name: branchName.value
                        }
                    }
                },
            ]
        }

        const pageTitle = (): string => {
            return `${branchName.value}支店の請求承認メッセージ一覧`
        }

        const onClickApproveByBranch = () => {
            showAffectedListDialog.value = true
        }

        const closeAffectedListDialog = () => {
            showAffectedListDialog.value = false
        }

        const onFinishApprovalByBranch = async () => {
            await updateApprovals()
            updateSearchedApprovals()
            showAffectedListDialog.value = false
        }

        return {
            backwardMonthCountText,
            searchText,
            acceptState,
            selectedAcceptState,
            FontSizes,
            searchedApprovals,
            branchName,
            pageTitle,
            ancestries,
            onClickApproveByBranch,
            showAffectedListDialog,
            closeAffectedListDialog,
            onFinishApprovalByBranch,
        }
    }
})
