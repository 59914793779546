export class Staff {
    staffId = -1            // staff_id: 19
    userName = ""           // user_name
    staffStatus = ""        // staff_status
    email1 = ""             // email1
    email2 = ""             // email2
    branch = ""             // branch:
    bank = ""               // bank
    bankNumber = ""         // bank_no
    bankBranch = ""         // bank_branch
    bankBranchNumber = ""   // bank_branch_no
    accountName = ""        // account_name
    accountNumber = ""      // account_no
    accountSubject = ""     // account_subject
    accountType = ""        // account_type
    payment = ""            // payment

    static fromJson(obj: any): Staff {
        const staff = new Staff()
        staff.staffId = obj.staff_id
        staff.userName = obj.user_name
        staff.email1 = obj.email1
        staff.email2 = obj.email2
        staff.branch = obj.branch
        staff.bank = obj.bank
        staff.bankNumber = obj.bank_no
        staff.bankBranch = obj.bank_branch
        staff.bankBranchNumber = obj.bank_branch_no
        staff.accountName = obj.account_name
        staff.accountNumber = obj.account_no
        staff.accountSubject = obj.account_subject
        staff.accountType = obj.account_type
        staff.payment = obj.payment
        return staff
    }

    static fromJsonArrToArr (objArr: Array<any>): Staff[] {
        const arr = [] as Staff[]
        objArr.forEach(obj => {
            arr.push(this.fromJson(obj))
        })
        return arr
    }

    toJsonObj(): any {
        return {
            staff_id: this.staffId,
            user_name: this.userName,
            email1: this.email1,
            email2: this.email2,
            branch: this.branch,
            bank: this.bank,
            bank_no: this.bankNumber,
            bank_branch: this.bankBranch,
            bank_branch_no: this.bankBranchNumber,
            account_name: this.accountName,
            account_no: this.accountNumber,
            account_subject: this.accountSubject,
            account_type: this.accountType,
            payment: this.payment,
        }
    }

    get stringForSearch (): string {
        return `${this.staffId} ${this.userName} ${this.accountName}`
    }

    get vForKey (): string {
        return `staff__${this.staffId.toString().padStart(10, "0")}`
    }

    get displayName (): string {
        let name = this.userName
        if (name.length === 0) {
            name = this.accountName
        }
        return name
    }

}