import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container-fluid" }
const _hoisted_2 = { class: "row justify-content-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Breadcrumbs = _resolveComponent("Breadcrumbs")!
  const _component_PDFViewer = _resolveComponent("PDFViewer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Breadcrumbs, {
      here: '月払い請求書',
      ancestries: _ctx.ancestries
    }, null, 8, ["ancestries"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("button", {
        class: "btn btn-outline-info",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClickBackToMonthInvoicePage && _ctx.onClickBackToMonthInvoicePage(...args)))
      }, "戻る")
    ]),
    _createElementVNode("div", {
      class: "row pdf-row mt-3",
      style: _normalizeStyle({height: _ctx.pdfRowHeight})
    }, [
      _createVNode(_component_PDFViewer, {
        class: "col pdf-col",
        style: _normalizeStyle(_ctx.pdfColStyle),
        "pdf-src-prop": _ctx.pdfSrc
      }, null, 8, ["style", "pdf-src-prop"])
    ], 4)
  ]))
}