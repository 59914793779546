import {createStore} from "vuex";

export const authUpdateOverlayMode = {
    SHOULD_UPDATE: "auth_update_mode__should_update",
    NORMAL_UPDATE: "auth_update_mode__normal_update",
}

type authUpdateOverlayStateType = {
    show: boolean,
    mode: string,
}

export const authUpdateOverlayStateStore = createStore<authUpdateOverlayStateType>({
    state: {
        show: false,
        mode: authUpdateOverlayMode.NORMAL_UPDATE
    },
    mutations: {
        showOverlay (state, payload) {

            if (payload && payload.mode) {
                state.mode = payload.mode
            }

            state.show = true
        },
        hideOverlay (state) {
            state.show = false
            state.mode = authUpdateOverlayMode.NORMAL_UPDATE
        },
    }
})