import {createStore} from "vuex";

const defaultWidth = 200

type toastMessageType = {
    message: string,
    show: boolean,
    width: number,
    keep: boolean,
}

export const toastStore = createStore<toastMessageType>({
    state: {
        message: "",
        show: false,
        width: defaultWidth,
        keep: false,
    },
    mutations: {
        post(state, payload) {

            // console.log("post")

            state.message = payload.message
            state.show = true

            if (payload.width) {
                state.width = payload.width
            } else {
                state.width = defaultWidth
            }

            if (payload.keep) {
                state.keep = payload.keep
            } else {
                state.keep = false
            }

            if (!state.keep) {
                setTimeout(() => {
                    state.show = false
                    state.message = ""
                }, 3000)
            }

        },
        hide (state) {
            state.show = false
            state.message = ""
            state.width = defaultWidth
        }
    }
})

