import { toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-42924aa1"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "toast-overlay" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Transition, { name: "fade" }, {
    default: _withCtx(() => [
      _withDirectives(_createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", {
          class: "custom-toast",
          style: _normalizeStyle({top: _ctx.toastTop() + 'px',
                        width: _ctx.toastStore.state.width + 'px',
                        fontSize: _ctx.FontSizes.small1})
        }, _toDisplayString(_ctx.toastStore.state.message), 5)
      ], 512), [
        [_vShow, _ctx.toastStore.state.show]
      ])
    ]),
    _: 1
  }))
}