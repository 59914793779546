export class Auth {
    userId = ""
    password = ""
    company = ""
    department = ""
    name = ""
    siteId = -1
    address = ""
    auth = -1
    cert = {
        password: "",
        mail: ""
    }

    get authLevel (): string {
        switch (this.auth) {
            case 1:
                return authLevels.DIST_STAFF
            case 2:
                return authLevels.VIEWER
            case 3:
                return authLevels.DIST_ADMIN
            case 4:
                return authLevels.ACCOUNTING_ADMIN
            case 5:
                return authLevels.ADMIN
            case 6:
                return authLevels.BOARD_MEMBER
            default:
                return authLevels.DIST_STAFF
        }
    }

    static fromJson(obj: any): Auth {
        const auth = new Auth()
        auth.userId = obj.user_id
        auth.password = obj.password
        auth.company = obj.company
        auth.department = obj.department
        auth.name = obj.name
        auth.siteId = obj.site_id
        auth.address = obj.address
        auth.auth = obj.auth

        auth.cert.password = obj.cert.password
        auth.cert.mail = obj.cert.mail

        return auth
    }

    toJsonObj(): any {
        return {
            user_id: this.userId,
            password: this.password,
            company: this.company,
            department: this.department,
            site_id: this.siteId,
            address: this.address,
            auth: this.auth
        }
    }

    replaceExceptCert(other: Auth|any): void {
        this.userId = other.userId
        this.password = other.password
        this.company = other.company
        this.department = other.department
        this.name = other.name
        this.siteId = other.siteId
        this.address = other.address
        this.auth = other.auth
    }

    replaceWithOther(other: Auth|any): void {

        this.replaceExceptCert(other)

        this.cert.password = other.cert.password
        this.cert.mail = other.cert.mail
    }
    get hasAccountingPrev(): boolean {
        return [4, 5, 6].includes(this.auth)
    }
    get hasAccountingAnyPrev(): boolean {
        return [3,4, 5, 6].includes(this.auth)
    }
}

export const authLevels = {
    BOARD_MEMBER: "board_member",
    ADMIN: "admin",                         // 5: 両方編集可能
    ACCOUNTING_ADMIN: "accounting_admin",   // 4: 経理担当、経理編集可、配布報告閲覧のみ
    DIST_ADMIN: "dist_admin",               // 3: 配布報告担当、配布報告編集可、経理閲覧のみ
    VIEWER: "viewer",                       // 2: 両方閲覧のみ可
    DIST_STAFF: "dist_staff",               // 1: 配布スタッフ、マイページのみアクセス可
}

export const certPasswordStatuses = {
    updated: "updated",
    notUpdated: "not_updated",
}

export const certMailStatuses = {
    verified: "verified",
    notVerified: "not_verified"
}


