
import {defineComponent, onMounted, PropType, ref} from "vue";
import {PostingRecordDiff} from "@/data-models/posting-record-diff";

export default defineComponent({
    name: "PostingRecordDiffRow",
    props: {
        recordDiff: {
            type: Object as PropType<PostingRecordDiff>,
            required: true
        }
    },
    setup(props) {

        const dataRow = ref<HTMLDivElement>(),
            oldDataRow = ref<HTMLDivElement>()

        const fixedStr = (num: number): string => {
            const decimal = props.recordDiff.diffState === "changed" ? 5 : 3
            return num.toFixed(decimal)
        }

        const updateDataRowHeight = (row: HTMLDivElement) => {
            let maxSpanHeight = 0
            const dataCols = row.children
            for (let i = 0; i < dataCols.length; i++) {
                const dataCol = dataCols.item(i)
                if (dataCol) {
                    const span = dataCol.children.item(0)
                    if (span) {
                        // console.log("span.clientHeight: ", span.clientHeight)
                        if (span.clientHeight > maxSpanHeight) {
                            maxSpanHeight = span.clientHeight
                        }
                        // (dataCol as HTMLDivElement).style.height = span.clientHeight + "px"
                    }
                }
            }
            row.style.height = maxSpanHeight + "px"
        }

        const waitAndUpdateHeights = () => {
            const timerId = window.setInterval(() => {
                if (dataRow.value) {
                    window.clearInterval(timerId)
                    updateDataRowHeight(dataRow.value)

                    if (oldDataRow.value) {
                        updateDataRowHeight(oldDataRow.value)
                    }
                }
            }, 50)
        }

        onMounted(() => {
            waitAndUpdateHeights()
        })

        let resizeTimerId = 0
        window.addEventListener("resize", () => {
            window.clearTimeout(resizeTimerId)
            resizeTimerId = window.setTimeout(() => {
                if (dataRow.value) {
                    updateDataRowHeight(dataRow.value)
                }

                if (oldDataRow.value) {
                    updateDataRowHeight(oldDataRow.value)
                }
            }, 300)
        })

        return {
            fixedStr,
            dataRow,
            oldDataRow,
        }
    }
})
