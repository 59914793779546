import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-08d82790"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "col-3 data-col" }
const _hoisted_2 = { class: "wrap-text" }
const _hoisted_3 = { class: "col-3 data-col" }
const _hoisted_4 = { class: "wrap-text" }
const _hoisted_5 = { class: "col-3 data-col" }
const _hoisted_6 = { class: "wrap-text" }
const _hoisted_7 = { class: "col-3 data-col" }
const _hoisted_8 = { class: "state-text-wrap" }
const _hoisted_9 = {
  key: 0,
  class: "state-text added"
}
const _hoisted_10 = {
  key: 1,
  class: "state-text deleted"
}
const _hoisted_11 = {
  key: 2,
  class: "state-text changed"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["row data-row", {'data-row-has-change': _ctx.dailyDiff.hasChange}]),
    style: _normalizeStyle({height: _ctx.rowHeight() + 'px'})
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.dailyDiff.dateString), 1)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.oldAmountStr()), 1)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.fixedStr(_ctx.dailyDiff.amount)), 1)
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", _hoisted_8, [
        (_ctx.dailyDiff.isAdded)
          ? (_openBlock(), _createElementBlock("p", _hoisted_9, "追加あり"))
          : _createCommentVNode("", true),
        (_ctx.dailyDiff.isDeleted)
          ? (_openBlock(), _createElementBlock("p", _hoisted_10, "削除あり"))
          : _createCommentVNode("", true),
        (_ctx.dailyDiff.isChanged)
          ? (_openBlock(), _createElementBlock("p", _hoisted_11, "変更あり"))
          : _createCommentVNode("", true)
      ])
    ])
  ], 6))
}