import { vModelDynamic as _vModelDynamic, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-70b564f2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "outer-frame input-frame" }
const _hoisted_2 = ["type"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("input", {
      type: _ctx.inputType(),
      class: "inner",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.passwordComputed) = $event)),
      spellcheck: "false"
    }, null, 8, _hoisted_2), [
      [_vModelDynamic, _ctx.passwordComputed]
    ]),
    _createElementVNode("div", {
      class: "show-pw-button eye icon",
      onMousedown: _cache[1] || (_cache[1] = ($event: any) => (_ctx.updateShowPassword(true))),
      onMouseleave: _cache[2] || (_cache[2] = ($event: any) => (_ctx.updateShowPassword(false))),
      onMouseup: _cache[3] || (_cache[3] = ($event: any) => (_ctx.updateShowPassword(false))),
      onTouchstart: _cache[4] || (_cache[4] = ($event: any) => (_ctx.updateShowPassword(true))),
      onTouchend: _cache[5] || (_cache[5] = ($event: any) => (_ctx.updateShowPassword(false))),
      onTouchleave: _cache[6] || (_cache[6] = ($event: any) => (_ctx.updateShowPassword(false))),
      onTouchcancel: _cache[7] || (_cache[7] = ($event: any) => (_ctx.updateShowPassword(false)))
    }, null, 32)
  ]))
}