
import {defineComponent, onMounted, ref, watch} from "vue";
import {useRoute, useRouter} from "vue-router";
import {backendApi} from "@/utils/backend-api";
import {Staff} from "@/data-models/staff";
import {logoutMessages, subPaths} from "@/utils/consts";
import {loadingOverlayStateStore} from "@/stores/loading-overlay-state-store";
import ClearableInput from "@/components/ClearableInput.vue";
import {FontSizes} from "@/utils/font-sizes";
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import {InvoiceApproval} from "@/data-models/invoice-approval";
import InvoiceApprovalListCell from "@/components/InvoiceApprovalListCell.vue";

export default defineComponent({
    name: "ApprovalList",
    components: {InvoiceApprovalListCell, Breadcrumbs, ClearableInput},
    setup() {

        const acceptState = {
            both: "both",
            accept : "accept",
            notAccept: "not_accept"
        }

        const route = useRoute(),
            router = useRouter()

        const  searchText = ref<string>(""),
                backwardMonthCountText = ref<string>("0"),
                searchedApprovals = ref<InvoiceApproval[]>([] as InvoiceApproval[]),
                selectedAcceptState = ref<string>(acceptState.both)

        let approvals = [] as InvoiceApproval[]

        const updateApprovals = async () => {
            console.log(backwardMonthCountText.value)
            const resp = await backendApi.inquireApprovalList(parseInt(backwardMonthCountText.value))
            if (resp.isAuthorized) {
                approvals = InvoiceApproval.fromJsonArrToArr(resp.value)
                console.log(approvals)
            } else {
                await router.push({
                    path: subPaths.LOGGED_OUT,
                    query: {
                        logout_message: logoutMessages.TOKEN_EXPIRED.key
                    }
                })
            }
        }

        const updateSearchedApprovals = () => {
            searchedApprovals.value.splice(0)
            filterApprovalsByAcceptState().forEach(apprv => {
                if (searchText.value.length === 0) {
                    searchedApprovals.value.push(apprv)
                } else {
                    if (apprv.stringForSearchWithBranch.includes(searchText.value)) {
                        searchedApprovals.value.push(apprv)
                    }
                }
            })
        }

        watch(() => backwardMonthCountText.value, async () => {
            await updateApprovals()
            updateSearchedApprovals()
        })

        watch(() => searchText.value, () => {
            updateSearchedApprovals()
        })

        watch(() => selectedAcceptState.value, () => {
            updateSearchedApprovals()
        })

        const filterApprovalsByAcceptState = (): InvoiceApproval[] => {
            const approvals2 = [] as InvoiceApproval[]
            approvals.forEach(apprv => {
                if (selectedAcceptState.value === acceptState.accept) {
                    if (apprv.accept) {
                        approvals2.push(apprv)
                    }
                } else if (selectedAcceptState.value === acceptState.notAccept) {
                    if (!apprv.accept) {
                        approvals2.push(apprv)
                    }
                } else {
                    approvals2.push(apprv)
                }
            })
            return approvals2
        }


        onMounted(async () => {

            searchText.value = ""

            await updateApprovals()
            updateSearchedApprovals()

            loadingOverlayStateStore.commit("fadeout")
        })

        return {
            backwardMonthCountText,
            searchText,
            acceptState,
            selectedAcceptState,
            FontSizes,
            searchedApprovals,
            ancestries: [
                {
                    name: "ホーム",
                    pathObj: subPaths.HOME
                }
            ],
        }
    }
})
