import {parse, format} from "date-fns"
import {datetimeFormat} from "@/utils/consts";

export class BranchOffice {

    id = -1                 // id: 1
    name = ""               // name: "新宿"
    zip = ""                // zip: "160-0022"
    address = ""            // address
    tel = ""                // tel: "03-6273-1045"
    fax = ""                // fax: "03-6273-1046"
    mail = ""               // mail: "souko@ato-co.jp"
    openDate = new Date()   // open_date: "2018/06/13 00:00:00"
    closeDate = new Date()  // close_date: "9999/12/31 00:00:00"
    deleteFlag = false      // delete_flag: false

    static fromJson(obj: any): BranchOffice {
        const branch = new BranchOffice()
        branch.id = obj.id
        branch.name = obj.name
        branch.zip = obj.zip
        branch.address = obj.address
        branch.tel = obj.tel
        branch.fax = obj.fax
        branch.mail = obj.mail
        branch.openDate = parse(obj.open_date, datetimeFormat, new Date())
        branch.closeDate = parse(obj.close_date, datetimeFormat, new Date())
        branch.deleteFlag = obj.delete_flag
        return branch
    }

    static fromJsonArrToArr(objArr: Array<any>): BranchOffice[] {
        const arr = [] as BranchOffice[]
        objArr.forEach(obj => {
            arr.push(this.fromJson(obj))
        })
        return arr
    }

    toJsonObj(): any {
        return {
            id: this.id,
            name: this.name,
            zip: this.zip,
            address: this.address,
            tel: this.tel,
            fax: this.fax,
            mail: this.mail,
            open_date: format(this.openDate, datetimeFormat),
            close_date: format(this.closeDate, datetimeFormat),
            delete_flag: this.deleteFlag,
        }
    }

    vForKey (): string {
        return `branch_office__${this.id.toString().padStart(3, "0")}_${this.name}`
    }



}