import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "switch-frame",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClickSwitch && _ctx.onClickSwitch(...args))),
    style: _normalizeStyle(_ctx.frameStyle())
  }, [
    _createElementVNode("div", {
      class: "switch-frame-cover",
      style: _normalizeStyle(_ctx.frameCoverStyle())
    }, null, 4),
    _createElementVNode("div", {
      class: "switch-knob",
      style: _normalizeStyle(_ctx.knobStyle())
    }, null, 4)
  ], 4))
}