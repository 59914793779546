import {parse} from "date-fns"
import {datetimeFormat, logoutMessages, subPaths} from "@/utils/consts";
import {Router, useRouter} from "vue-router";

export class ApiResponse {
    description = ""
    errorMessage = ""
    executedAt = new Date()
    result = ""
    value: any
    status = 0

    get isAuthorized (): boolean {
        return this.status === 200
    }

    get isSuccessful (): boolean {
        return this.result === "success"
    }

    static fromJson(obj: any): ApiResponse {
        const resp = new ApiResponse()
        resp.description = obj.description
        resp.errorMessage = obj.error_message
        resp.executedAt = parse(obj.executed_at, datetimeFormat, new Date())
        // console.log(obj.executed_at, resp.executedAt)
        resp.value = obj.value
        resp.result = obj.result
        return resp
    }
}