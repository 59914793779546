import {
    createRouter,
    createWebHashHistory,
    NavigationGuardNext,
    RouteLocationNormalized,
    RouteRecordNormalized,
    RouteRecordRaw
} from 'vue-router'

import Login from "@/components/Login.vue"
import Home from "@/components/Home.vue";
import {authStore} from "@/stores/auth-store";
import {backendApi} from "@/utils/backend-api";
import {invoiceStatuses, logoutMessages, passwordUpdateDialogMode, subPaths} from "@/utils/consts";
import LoggedOut from "@/components/LoggedOut.vue";
import MyPage from "@/components/MyPage.vue";
import Branch from "@/components/Branch.vue";
import {authLevels} from "@/data-models/auth";
import PaymentAggregation from "@/components/PaymentAggregation.vue";
import {loadingOverlayStateStore} from "@/stores/loading-overlay-state-store";
import Prepayment from "@/components/Prepayment.vue";
import InvoiceOfMonthPage from "@/components/InvoiceOfMonthPage.vue";
import ApprovalList from "@/components/ApprovalList.vue";
import ApprovalListForBranch from "@/components/ApprovalListForBranch.vue";
import InvoiceStatusSettings from "@/components/InvoiceStatusSettings.vue";
import {authUpdateOverlayMode, authUpdateOverlayStateStore} from "@/stores/auth-update-overlay-state-store";
import MonthInvoicePDFPage from "@/components/MonthInvoicePDFPage.vue";
import PostingDataDiffPage from "@/components/PostingDataDiffPage.vue";
import PostingRecordsAllDiff from "@/components/PostingRecordsAllDiff.vue";
import PostingRecordsBranchDiff from "@/components/PostingRecordsBranchDiff.vue";
import PaymentStopPage from "@/components/PaymentStopPage.vue";
import InvoiceResendPage from "@/components/InvoiceResendPage.vue";

// ブラウザバックを検知するための仕組み
window.addEventListener("popstate", () => {
    console.log("popstate")
    loadingOverlayStateStore.commit("switchLoadingState", { show: true })
})


const routes: Array<RouteRecordRaw> = [
    {
        path: subPaths.HOME,
        name: "Home",
        component: Home,
    },
    {
        path: subPaths.BRANCH_PAGE,
        name: "BranchPage",
        component: Branch
    },
    {
        path: subPaths.BRANCH_PAGE_APPROVAL_LIST,
        name: "ApprovalListForBranch",
        component: ApprovalListForBranch
    },
    {
        path: subPaths.APPROVAL_LIST,
        name: "InvoiceMessages",
        component: ApprovalList
    },
    {
        path: subPaths.UPDATE_INVOICE_STATUS,
        name: "InvoiceStatusSettings",
        component: InvoiceStatusSettings
    },
    {
        path: subPaths.LOGIN,
        name: "Login",
        component: Login,
    },
    {
        path: subPaths.LOGGED_OUT,
        name: "LoggedOut",
        component: LoggedOut,
    },
    {
        path: subPaths.MY_PAGE,
        name: "MyPage",
        component: MyPage,
    },
    {
        path: subPaths.PAYMENT_AGGREGATION,
        name: "PaymentAggregation",
        component: PaymentAggregation,
    },
    {
        path: subPaths.MY_PAGE_PREPAYMENT,
        name: "MyPrepayment",
        component: Prepayment,
    },
    {
        path: subPaths.MY_PAGE_INVOICE_OF_MONTH,
        name: "MyInvoiceOfMonth",
        component: InvoiceOfMonthPage,
    },
    {
        path: subPaths.MY_PAGE_INVOICE_OF_MONTH_PDF,
        name: "MonthInvoicePDFPage",
        component: MonthInvoicePDFPage,
    },
    {
        path: subPaths.MY_PAGE_POSTING_DATA_DIFF,
        name: "PostingDataDiffPage",
        component: PostingDataDiffPage,
    },
    {
        path: subPaths.MY_PAGE_PAYMENT_STOP,
        name: "PaymentStopPage",
        component: PaymentStopPage,
    },
    {
        path: subPaths.MY_PAGE_INVOICE_RESEND,
        name: "InvoiceResendPage",
        component: InvoiceResendPage,
    },
    {
        path: subPaths.POSTING_RECORDS_ALL_DIFF,
        name: "PostingRecordsAllDiff",
        component: PostingRecordsAllDiff,
    },
    {
        path: subPaths.POSTING_RECORDS_BRANCH_DIFF,
        name: "PostingRecordsBranchDiff",
        component: PostingRecordsBranchDiff,
    },
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

router.beforeEach(async (to, from, next) => {
    console.log("authStore.getters.shouldUpdateAuths: ", authStore.getters.shouldUpdateAuths)

    // 認証情報更新画面は配布スタッフのみ表示する。
    if (authStore.getters.existsToken
        && authStore.getters.shouldUpdateAuths
        && (authStore.getters.authLevel === authLevels.DIST_STAFF
            || authStore.getters.authLevel === authLevels.DIST_ADMIN
            || authStore.getters.authLevel === authLevels.ACCOUNTING_ADMIN
            || authStore.getters.authLevel === authLevels.VIEWER
            || authStore.getters.authLevel === authLevels.BOARD_MEMBER)
    )
    {
        console.log("authStore.getters.shouldUpdateAuths")
        authUpdateOverlayStateStore.commit("showOverlay", {
            mode: authUpdateOverlayMode.SHOULD_UPDATE
        })
    }
    await verifyTokenAndRedirectIfNeeded(to, from, next)
})

const verifyTokenAndRedirectIfNeeded = async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {

    console.log("verifyTokenAndRedirectIfNeeded: " + to.path)

    const token = to.query.token as string
    console.log(token)

    if (token && token.length > 0) {
        // クエリパラメタにトークンが入っている
        console.log("Token exists in query!")
        const isTokenValid = await backendApi.verifyToken(token as string)
        if (isTokenValid) {
            await redirectForAuthLevel(to, from, next)
        } else {
            next(subPaths.LOGIN)
        }
        return
    } else {
        // クエリパラメタにトークンが入っていない
        // トークンはストアに保持されているか
        if (authStore.getters.existsToken) {
            console.log("Token exists in store!")
            // トークンが保持されている
            const isTokenValid2 = await backendApi.verifyToken(authStore.state.token)
            // 認証に成功したならトークンが保存されている
            if (isTokenValid2) {
                console.log("Token in store is valid!")
                await redirectForAuthLevel(to, from, next)
            } else {
                console.log("Token is not valid!")
                // 保持されていたトークンが期限切れなどの理由で破棄された

                // 最後のトークンがアップデートされてから一定の時間がたっていたら、ログアウトメッセージは出さずにログイン画面を表示する。
                // 操作中にしばらく放置してトークンが切れた場合などはログアウトメッセージを出したほうが良いのでこのような処理にする。
                if (authStore.getters.shouldShowLoggedOut) {
                    next({
                        path: subPaths.LOGGED_OUT,
                        query: {
                            logout_message: logoutMessages.TOKEN_EXPIRED.key
                        }
                    })
                } else {
                    next({path: subPaths.LOGIN})
                }
            }
        } else {
            // トークンが保持されていない
            console.log("Tried to request without token preserved!")

            if (to.path === subPaths.LOGGED_OUT) {
                next()
            } else if (to.path !== subPaths.LOGIN) {
                console.log("Token not preserved, but trying access non-login page!")

                // ログイン以外のパスを指定していたならログインへリダイレクト
                next({path: subPaths.LOGIN})
                window.location.hash = subPaths.LOGIN
            } else {
                // ログインを指定していたならそのまま
                // console.log(to.path)
                loadingOverlayStateStore.commit("fadeout")
                next()
            }
        }
    }
}

// トークンが有効な状態でアクセスしたときのリダイレクト
const redirectForAuthLevel = async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
    // Deprecated: 20220326: 配布スタッフはマイページのみのアクセス
    // 配布スタッフはマイページ、前払い申し込み、月払い請求書発行ページへアクセス可

    console.log("authLevel: " + authStore.state.auth.authLevel)
    console.log("from.path: " + from.path)
    console.log("to.path: " + to.path)

    if (to.path === from.path) {
        loadingOverlayStateStore.commit("fadeout")
    }

    if (to.path === subPaths.MY_PAGE
        || to.path === subPaths.MY_PAGE_PREPAYMENT || to.path === subPaths.MY_PAGE_POSTING_DATA_DIFF) {

        next()

    } else if (to.path === subPaths.MY_PAGE_INVOICE_OF_MONTH || to.path === subPaths.MY_PAGE_INVOICE_OF_MONTH_PDF) {
        const statusResp = await backendApi.inquireSiteStatus()
        if (statusResp.isAuthorized) {
            // URL入力で請求承認ページに配布スタッフがアクセスしようとしたとき請求承認受付中でなければマイページに戻す
            if (statusResp.value === invoiceStatuses.APPROVAL_ACCEPT) {
                next()
            } else {
                console.log("Dist staff tried to invoice approval page while it is closed, then redirected to my page.")
                next({
                    path: subPaths.MY_PAGE,
                    query: {
                        staff_id: authStore.state.auth.userId,
                    }
                })
                window.location.hash = subPaths.MY_PAGE
            }
        } else {
            next({path: subPaths.LOGIN})
            window.location.hash = subPaths.LOGIN
        }
    } else {
        if (authStore.state.auth.authLevel === authLevels.DIST_STAFF) {
            // 配布スタッフがトークン有効状態でマイページ関連以外にアクセスしようとした場合、マイページにリダイレクトする。
            console.log("Dist staff tries to access non-my-page")
            next({
                path: subPaths.MY_PAGE,
                query: {
                    staff_id: authStore.state.auth.userId,
                }
            })
        } else {
            if (to.path === subPaths.LOGIN
                || to.path === subPaths.LOGGED_OUT) {
                // トークンが有効なのにログイン/ログアウトページに行こうとしている。
                // HOMEへリダイレクトしたのにURLバーが/loginのままの件
                // どうすればURLバーも書き換えられるのか
                // -> window.locationでURLを書き換えてよいらしい


                // window.locationでURLを書き換えるとリロードが発生してしまいそうだが、目視する限りは起きていない。
                // ハッシュモードだから回避できているのか
                window.location.hash = subPaths.HOME

                next({
                    path: subPaths.HOME,
                })

                // window.setTimeout(() => {
                //     loadingOverlayStateStore.commit("fadeout")
                // }, 500)

            } else {
                next()
            }
        }
    }
}


export default router
