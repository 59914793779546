import {PostingRecordDiff} from "@/data-models/posting-record-diff";
import {format, isSameDay, toDate} from "date-fns";
import {v4 as uuidv4} from "uuid";

export class DailyDiff {
    date = new Date()
    amount = 0
    oldAmount = 0

    isAdded = false
    isDeleted = false
    isChanged = false

    vForKey = `daily_diff_${uuidv4()}`

    static fromPostingRecordDiffArrToArr(recDiffArr: PostingRecordDiff[]): DailyDiff[] {

        const dDiffs = [] as DailyDiff[]

        for (let i = 0; i < recDiffArr.length; i++) {

            const rDiff = recDiffArr[i]
            let sameDateFound = false

            for (let j = 0; j < dDiffs.length; j++) {
                const dDiff = dDiffs[j]
                if (isSameDay(rDiff.date, dDiff.date)) {
                    sameDateFound = true

                    switch (rDiff.diffState) {
                        case "added":
                            dDiff.amount += rDiff.amount
                            dDiff.isAdded = true
                            break
                        case "deleted":
                            dDiff.oldAmount += rDiff.amount
                            dDiff.isDeleted = true
                            break
                        case "changed":
                            dDiff.amount += rDiff.amount
                            if (rDiff.oldData) {
                                dDiff.oldAmount += rDiff.oldData.amount
                            }
                            dDiff.isChanged = true
                            break
                        default:
                            // ステートなし
                            dDiff.amount += rDiff.amount
                            dDiff.oldAmount += rDiff.amount
                            break
                    }
                    break
                }
            }

            if (!sameDateFound) {
                const dDiff2 = new DailyDiff()
                dDiff2.date = toDate(rDiff.date)

                switch (rDiff.diffState) {
                    case "added":
                        dDiff2.amount = rDiff.amount
                        dDiff2.isAdded = true
                        break
                    case "deleted":
                        dDiff2.oldAmount = rDiff.amount
                        dDiff2.isDeleted = true
                        break
                    case "changed":
                        dDiff2.amount = rDiff.amount
                        if (rDiff.oldData) {
                            dDiff2.oldAmount = rDiff.oldData.amount
                        }
                        dDiff2.isChanged = true
                        break
                    default:
                        // ステートなし
                        dDiff2.amount = rDiff.amount
                        dDiff2.oldAmount = rDiff.amount
                        break
                }

                // テスト用
                // dDiff2.isAdded = true
                // dDiff2.isDeleted = true
                // dDiff2.isChanged = true

                dDiffs.push(dDiff2)
            }
        }
        return dDiffs
    }

    get dateString(): string {
        return format(this.date, "M/d")
    }

    get hasChange (): boolean {
        return this.isAdded || this.isDeleted || this.isChanged
    }
}

