
import {computed, defineComponent, onMounted} from "vue";
import {authStore} from "@/stores/auth-store";
import {useRoute, useRouter} from "vue-router";
import {logoutMessages, subPaths} from "@/utils/consts";
import LoadingOverlay from "@/components/LoadingOverlay.vue";
import {loadingOverlayStateStore} from "@/stores/loading-overlay-state-store";
import {useDevice} from "next-vue-device-detector";
import {FontSizes} from "@/utils/font-sizes";
import {authLevels} from "@/data-models/auth";
import ConfirmDialog from "@/components/ConfirmDialog.vue";
import Toast from "@/components/Toast.vue";
import AuthUpdateOverlay from "@/components/AuthUpdateOverlay.vue";
import {authUpdateOverlayMode, authUpdateOverlayStateStore} from "@/stores/auth-update-overlay-state-store";
import {toastStore} from "@/stores/toast-store";

export default defineComponent({
    name: "App",
    components: {
        AuthUpdateOverlay,
        Toast,
        ConfirmDialog,
        LoadingOverlay
    },
    setup() {

        const router = useRouter(),
                route = useRoute()

        FontSizes.init(useDevice())

        const onClickLogout = () => {
            authStore.commit("clearAuth")
            authStore.commit("clearToken")
            router.push({
                path: subPaths.LOGGED_OUT,
                query: {
                    logout_message: logoutMessages.NORMAL_LOGOUT.key
                }
            })
        }

        const onClickAppTitle = () => {

            if (!canClickAppTitle()) {
                return
            }

            loadingOverlayStateStore.commit("switchLoadingState", {show: true})
            router.push(subPaths.HOME)
        }

        const canClickAppTitle = (): boolean => {
            // console.log(route.path)
            return ![subPaths.HOME, subPaths.LOGIN, subPaths.LOGGED_OUT].includes(route.path)
                    && authStore.state.auth.authLevel !== authLevels.DIST_STAFF
        }

        const onClickChangePassword = () => {
            authUpdateOverlayStateStore.commit("showOverlay", {
                mode: authUpdateOverlayMode.NORMAL_UPDATE
            })
        }

        // onMounted(() => {
        //     toastStore.commit("post", {
        //         message: `${window.innerWidth} × ${window.innerHeight}`,
        //         keep: true,
        //     })
        // })

        return {
            authStore,
            onClickLogout,
            FontSizes,
            onClickAppTitle,
            canClickAppTitle,
            onClickChangePassword,
            authUpdateOverlayStateStore,
        }
    }
})
