import {toastStore} from "@/stores/toast-store";

export class FontSizes {

    private static _device: any | null = null
    private static readonly small1Base = 0.8
    private static readonly normalBase = 1
    private static readonly middle1Base = 1.1
    private static readonly large1Base = 1.5
    private static readonly large2Base = 2

    private static _innerWidth = 0

    static init(d: any): void {
        this._device = d
        this._innerWidth = window.innerWidth

        // window.addEventListener("resize", () => {
        //
        //     this._innerWidth = window.innerWidth
        //     toastStore.commit("post", {message: this._innerWidth})
        // })
    }

    private static baseToPxSize(base: number): number {
        return base * 14
    }

    private static baseToVwSize(base: number): number {
        // return base * this._innerWidth * 0.04
        return base * 3
    }

    static get small1 (): string {
        if (!this._device) {
            return this.baseToPxSize(this.small1Base) + "px"
        } else {
            return this._device.mobile
                ? this.baseToVwSize(this.small1Base) + "vw"
                : this.baseToPxSize(this.small1Base) + "px"
        }
    }

    static get normal (): string {
        if (!this._device) {
            return this.baseToPxSize(this.normalBase) + "px"
        } else {
            return this._device.mobile
                ? this.baseToVwSize(this.normalBase) + "vw"
                : this.baseToPxSize(this.normalBase) + "px"
        }
    }

    static get normalInPx (): number {
        if (!this._device) {
            return this.baseToPxSize(this.normalBase)
        } else {
            return this._device.mobile
                ? this.baseToVwSize(this.normalBase)
                : this.baseToPxSize(this.normalBase) * 100
        }
    }

    static get middle1 (): string {
        if (!this._device) {
            return this.baseToPxSize(this.middle1Base) + "px"
        } else {
            return this._device.mobile
                ? this.baseToVwSize(this.middle1Base) + "vw"
                : this.baseToPxSize(this.middle1Base) + "px"
        }
    }

    static get large1 (): string {
        if (!this._device) {
            return this.baseToPxSize(this.large1Base) + "px"
        } else {
            return this._device.mobile
                ? this.baseToVwSize(this.large1Base) + "vw"
                : this.baseToPxSize(this.large1Base) + "px"
        }
    }

    static get large2 (): string {
        if (!this._device) {
            return this.baseToPxSize(this.large2Base) + "px"
        } else {
            return this._device.mobile
                ? this.baseToVwSize(this.large2Base) + "vw"
                : this.baseToPxSize(this.large2Base) + "px"
        }
    }

    static get testColor (): string {
        if (!this._device) {
            return "red"
        } else {
            return this._device.mobile ? "blue" : "green"
        }
    }
}