

import {defineComponent, nextTick, ref} from "vue";
import {loadingOverlayStateStore} from "@/stores/loading-overlay-state-store";
import {FontSizes} from "@/utils/font-sizes";

export default defineComponent({
    name: "LoadingOverlay",
    setup() {

        const message = ref<string>("")

        loadingOverlayStateStore.watch(state => state.message, msg => {
            message.value = msg
        })

        return {
            loadingOverlayStateStore,
            message,
            FontSizes,
        }
    }
})
