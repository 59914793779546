
import {defineComponent, PropType} from "vue";
import {DailyDiff} from "@/data-models/daily-diff";

export default defineComponent({
    name: "DailyDiffRow",
    props: {
        dailyDiff: {
            type: Object as PropType<DailyDiff>,
            required: true
        }
    },
    setup(props) {

        const fixedStr = (num: number): string => {
            const decimal = props.dailyDiff.hasChange ? 5 : 3
            return num.toFixed(decimal)
        }

        const oldAmountStr = () => {
            return props.dailyDiff.hasChange
                ? fixedStr(props.dailyDiff.oldAmount)
                : "--"
        }

        const rowHeight = (): number => {
            let h = 0
            if (props.dailyDiff.isAdded) {
                h += 30
            }

            if (props.dailyDiff.isDeleted) {
                h += 30
            }

            if (props.dailyDiff.isChanged) {
                h += 30
            }

            return h > 40 ? h : 40
        }

        return {
            fixedStr,
            oldAmountStr,
            rowHeight,
        }
    }
})
